import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSLegalTextModalStyle {
  export const ModalContentDiv = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: ${(props) => (props.isMobile ? "90vw" : "60vw")};
    height: ${(props) => (props.isMobile ? "70vh" : "80vh")};
    padding: ${(props) => (props.isMobile ? "24px" : "84px")};
    border-radius: 16px;
    overflow-y: auto;
    background-color: ${colors.light.light};
    scrollbar-width: thin;
    scrollbar-color: ${colors.blue.blue01} ${colors.neutrals.grey01};

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${colors.neutrals.grey01};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.blue.blue01};
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: ${colors.blue.blue01};
    }
  `;

  export const ModalContentDesc = styled.div`
    font-size: ${typography.smallText2.size};
    line-height: ${typography.smallText2.lineHeight};
    font-weight: ${typography.fontWeights.regular};
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
  `;

  export const ModalTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: ${typography.pharagraph1.size};
    line-height: ${typography.pharagraph1.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    font-family: "ReadexPro";
    color: ${colors.dark.dark};
  `;
}
