import styled from "styled-components";
import colors from "../../../../style/colors";

export namespace NSCVPageDesktopStyle {
  export const Container = styled.div`
    width: 100%;
  `;

  export const MainContainer = styled.div`
    font-family: "ReadexPro";
    padding: 84px 112px;

    overflow: hidden;
    background-color: ${colors.background.bg};
    position: relative;
    display: flex;
    gap: 56px;
    width: 100%;
    padding-bottom: 120px;
    @media (max-width: 1400px) {
      padding: 84px;
      padding-bottom: 40px;
      gap: 48px;
    }
    @media (max-width: 1248px) {
      padding: 84px 36px;
      padding-bottom: 40px;
      gap: 32px;
    }
    @media (max-width: 1000px) {
      padding: 84px 48px;
      padding-bottom: 40px;
      gap: 24px;
    }
  `;
  export const Content = styled.div`
    @media (min-width: 1248px) {
      max-width: 940px;
    }

    width: 100%;
  `;

  export const PageTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 37.44px;
    text-align: left;
    margin-bottom: 24px;
  `;

  export const PageLabel = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 28.08px;
    text-align: left;
    margin-bottom: 24px;
  `;

  export const FormContainer = styled.div`
    background-color: ${colors.light.light};
    padding: 32px;
    margin-top: 8px;
    border-radius: 16px;
  `;

  export const FormContainerWithoutBg = styled.div`
    margin-top: 8px;
  `;

  export const FormTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    text-align: left;
    margin-bottom: 24px;
    display: flex;
  `;

  export const Star = styled.span`
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    text-align: left;
    color: ${colors.dark.dark};
  `;

  export const OtherContainer = styled.span`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    gap: 16px;
    align-items: center;
  `;

  export const OtherTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 37.44px;
    text-align: left;
    margin-bottom: 24px;
    display: flex;
  `;

  export const CvUploadContainer = styled.div`
    padding: 0 12px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: fit-content;
    height: 52px;
    margin-bottom: 24px;
    border-radius: 8px;
    background-color: ${colors.shadesOflight.light32};
    font-weight: 400px;
    font-size: 18px;
    line-height: 28.08px;
    gap: 6px;
  `;
}
