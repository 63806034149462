import { z } from "zod";

export namespace NSCvPersonalInfoType {
  export type ICvPersonalInfo = {
    onSubmitPersonalInfo: (data: CVPersonalInfoType) => void;
    onSubmitOther: (data: { cvName: string }) => void;
  };

  export const CvPersonalInfoSchema = z
    .object({
      name: z.string().min(1, "Ad zorunlu"),
      surname: z.string().min(1, "Soyad zorunlu"),
      birthDate: z
        .string({ required_error: "Zorunlu", invalid_type_error: "Zorunlu" })
        .min(1, "Dogum tarihi zorunlu"),
      nationality: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Dogum tarihi zorunlu",
            required_error: "Dogum tarihi zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Uyruk yeri zorunlu",
          path: ["nationality"],
        }),
      gender: z
        .object(
          { id: z.string(), label: z.string() },
          {
            invalid_type_error: "Cinsiyet zorunlu",
            required_error: "Cinsiyet zorunlu",
          }
        )
        .refine((data) => data.id && data.label, {
          message: "Cinsiyet zorunlu",
          path: ["gender"],
        }),
      driverLicence: z.string().min(1, "Ehliyet bilgisi zorunlu"),
      driverClass: z.object({ id: z.string(), label: z.string() }).optional(),
      activeDrivingInfo: z.string().optional(),
      militaryStatus: z.string().optional(),
      dischargeDate: z.string().optional(),
      postponementDate: z.string().optional(),
      exemptionReason: z.string().optional(),
    })
    .refine(
      (data) => {
        if (data.driverLicence.toLowerCase() === "yes") {
          return !!data.driverClass;
        }
        return true;
      },
      {
        message: "Ehliyet sınıfı bilgisi zorunludur",
        path: ["driverClass"],
      }
    )
    .refine(
      (data) => {
        if (data.driverLicence.toLowerCase() === "yes") {
          return (
            data.activeDrivingInfo && data.activeDrivingInfo.trim().length > 0
          );
        }
        return true;
      },
      {
        message: "Aktif araç kullanma bilgisi zorunlu",
        path: ["activeDrivingInfo"],
      }
    )
    .refine(
      (data) => {
        if (data.gender.label.toLowerCase() === "male") {
          return !!data.militaryStatus;
        }
        return true;
      },
      {
        message: "Askerlik durumu zorunludur",
        path: ["militaryStatus"],
      }
    );
  export type CVPersonalInfoType = z.infer<typeof CvPersonalInfoSchema>;
}
