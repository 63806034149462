import styled from "styled-components";

export namespace NSCVOtherStyle {
  export const Container = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: "ReadexPro";
  `;

  export const DragAndDropContainer = styled.div`
    max-width: 360px;
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  `;

  export const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    margin-bottom: 4px;
  `;

  export const Description = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 156%;
    margin-bottom: 32px;
  `;

  export const Link = styled.a`
    color: ${({ theme }) => theme.colors.blue.blue01};
    font-size: 16px;
    font-weight: 400;
    margin-right: 2px;
  `;
}
