import { useState, useRef } from "react";
import ReactPlayer from "react-player";
import { NSVideoPlayerCardStyle } from "./VideoPlayerCard.style";
import { NSVideoPlayerCardType } from "./VideoPlayerCard.types";
import { ReactComponent as Play } from "../../assets/icons/play.svg";

const PADDING = 24;
export const VideoPlayerCard = ({
  isMobile,
  title,
  description,
  imageUrl,
  onClick,
  showTitle = true,
  width,
  height,
  videoUrl,
}: NSVideoPlayerCardType.IVideoPlayerCard) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef<ReactPlayer>(null);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);

    if (onClick) {
      onClick();
    }
  };

  const containerStyle = {
    position: "relative" as const,
    width: width || "100%",
    height: height || "100%",
  };

  return (
    <>
      <NSVideoPlayerCardStyle.OuterContainer isMobile={isMobile}>
        <NSVideoPlayerCardStyle.Container
          width={width}
          height={height}
          isMobile={isMobile}
          onClick={!isPlaying ? handlePlayPause : undefined}
          showTitle={showTitle}
        >
          {!isPlaying ? (
            <>
              <NSVideoPlayerCardStyle.Image
                src={imageUrl}
                isMobile={isMobile}
              />
              <NSVideoPlayerCardStyle.PlayOrb>
                <Play />
              </NSVideoPlayerCardStyle.PlayOrb>
            </>
          ) : (
            <div style={containerStyle}>
              <ReactPlayer
                ref={playerRef}
                url={videoUrl}
                width={isMobile ? window.innerWidth - PADDING * 2 : "369px"}
                height={isMobile ? "184.31px" : "208px"}
                playing={isPlaying}
                controls={true}
                volume={1}
                muted={false}
                onEnded={() => setIsPlaying(false)}
                onClick={(e: any) => e.stopPropagation()}
                onPlay={() => setIsPlaying(true)}
                playIcon={<Play />}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      disablePictureInPicture: true,
                    },
                  },
                }}
              />
            </div>
          )}
        </NSVideoPlayerCardStyle.Container>
        {showTitle && (
          <NSVideoPlayerCardStyle.TextContainer isMobile={isMobile}>
            <NSVideoPlayerCardStyle.TitleContainer isMobile={isMobile}>
              {title}
            </NSVideoPlayerCardStyle.TitleContainer>
            <NSVideoPlayerCardStyle.DescriptionContainer isMobile={isMobile}>
              {description}
            </NSVideoPlayerCardStyle.DescriptionContainer>
          </NSVideoPlayerCardStyle.TextContainer>
        )}
      </NSVideoPlayerCardStyle.OuterContainer>
    </>
  );
};
