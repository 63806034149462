import styled from "styled-components";
import { NSSelectBoxStyle } from "../SelectBox/SelectBox.style";

export namespace NSDatePickerStyle {
  export const Container = styled.div`
    font-family: "ReadexPro";
    width: 100%;

    .react-datepicker-wrapper {
      width: 100%;
    }

    .custom-datepicker-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      font-weight: bold;
    }

    .datepicker-nav-btn {
      border: none;
      background: none;
      cursor: pointer;
    }

    .datepicker-nav-btn-left {
      rotate: calc(180deg);
    }

    .datepicker-nav-btn:disabled {
      display: none !important;
    }
    .empty-div {
      width: 24px;
      height: 24px;
    }

    .custom-datepicker-title {
      font-weight: bold;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 1px;
      font-family: "ReadexPro";
    }

    .year-selectbox-container,
    .month-selectbox-container {
      ${NSSelectBoxStyle.TitleContainer} {
        width: fit-content;
        border: 0px;
        padding: 0;
        margin-left: 2px;
      }

      ${NSSelectBoxStyle.Title} {
        padding-right: 0;
        font-weight: bold;
      }
      ${NSSelectBoxStyle.ItemContainer} {
        top: 25px;
        height: 150px;
      }
    }
  `;
}
