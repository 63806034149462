import { useEffect, useRef, useState } from "react";
import { NSDatePickerStyle } from "./DatePicker.style";
import { NSDatePickerType } from "./DatePicker.types";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { Input } from "../Input/Input";
import { ReactComponent as CalendarEmpty } from "../../assets/icons/calendarEmpty.svg";
import { tr } from "date-fns/locale/tr";
import { SelectBox } from "../SelectBox/SelectBox";
import { NSSelectBoxType } from "../SelectBox/SelectBox.types";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevronRight.svg";

registerLocale("tr", tr);

export const DatePicker = ({
  placeholder,
  infoText,
  dateFormat,
  error,
  onChange,
  defaultDate,
  isRequired,
  minDate,
  maxDate = new Date(),
}: NSDatePickerType.IDatePicker) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(defaultDate);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const isYearDropdownClick =
      target.classList.contains("react-datepicker__year-option") ||
      target.classList.contains("react-datepicker__year-read-view") ||
      target.classList.contains(
        "react-datepicker__year-read-view--down-arrow"
      ) ||
      target.classList.contains(
        "react-datepicker__year-read-view--selected-year"
      ) ||
      target.classList.contains("react-datepicker__year-dropdown") ||
      target.closest(".react-datepicker__year-dropdown") !== null ||
      target.closest(".react-datepicker__year-read-view") !== null;

    const isSelectBoxClick =
      target.closest(".NSSelectBoxStyle-Container") !== null ||
      target.closest(".NSSelectBoxStyle-ItemContainer") !== null ||
      target.closest(".NSSelectBoxStyle-Item") !== null ||
      target.closest(".NSSelectBoxStyle-TitleContainer") !== null ||
      target.closest("sc-flBipw gvCkFW") ||
      target.closest("sc-jNwOwP iNRTZq") ||
      target.classList.contains("year-selectbox-container") ||
      target.classList.contains("month-selectbox-container") ||
      target.classList.contains("sc-flBipw") ||
      target.classList.contains("sc-jNwOwP") ||
      target.classList.contains("sc-buvPDS") ||
      target.closest(".year-selectbox-container") !== null ||
      target.closest(".month-selectbox-container") !== null;

    const isAnyDropdownClick = isSelectBoxClick || isYearDropdownClick;

    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      !isAnyDropdownClick
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const prepareDateString = (date: Date | undefined) => {
    if (!date) return "";
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year = date.getFullYear();
    if (dateFormat === "MM/yyyy") {
      return `${month}/${year}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  };

  const onChangeHandler = (date: Date) => {
    setStartDate(date);
    onChange(date);
    setIsOpen(false);
  };

  const years = Array.from(
    { length: 90 },
    (_, i) => new Date().getFullYear() - i
  );

  const months = [
    { id: "0", label: "Ocak" },
    { id: "1", label: "Şubat" },
    { id: "2", label: "Mart" },
    { id: "3", label: "Nisan" },
    { id: "4", label: "Mayıs" },
    { id: "5", label: "Haziran" },
    { id: "6", label: "Temmuz" },
    { id: "7", label: "Ağustos" },
    { id: "8", label: "Eylül" },
    { id: "9", label: "Ekim" },
    { id: "10", label: "Kasım" },
    { id: "11", label: "Aralık" },
  ];

  const currentYear = startDate?.getFullYear() || new Date().getFullYear();
  const currentMonth = startDate?.getMonth() || new Date().getMonth();

  const yearItems: NSSelectBoxType.ISelectBoxItem[] = years.map((year) => ({
    id: year.toString(),
    label: year.toString(),
  }));

  const monthItems: NSSelectBoxType.ISelectBoxItem[] = months;

  const defaultSelectedYearItem = yearItems.find(
    (item) => item.id === currentYear.toString()
  );

  const defaultSelectedMonthItem = monthItems.find(
    (item) => item.id === currentMonth.toString()
  );

  return (
    <NSDatePickerStyle.Container ref={containerRef}>
      <ReactDatePicker
        id="datePicker"
        closeOnScroll={(e) => e.target === document}
        showYearDropdown
        maxDate={maxDate}
        minDate={minDate}
        locale="tr"
        yearDropdownItemNumber={90}
        scrollableYearDropdown
        selected={startDate}
        onChange={onChangeHandler}
        onInputClick={() => setIsOpen(true)}
        open={isOpen}
        shouldCloseOnSelect={false}
        dateFormat={dateFormat ?? "dd/MM/yyyy"}
        placeholderText={placeholder}
        renderCustomHeader={({
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="custom-datepicker-header">
            {!prevMonthButtonDisabled ? (
              <ChevronRight
                onClick={decreaseMonth}
                className={"datepicker-nav-btn datepicker-nav-btn-left"}
              />
            ) : (
              <div className="empty-div" />
            )}

            <div className="custom-datepicker-title">
              <div className="month-selectbox-container">
                <SelectBox
                  items={monthItems}
                  defaultSelectedItem={defaultSelectedMonthItem}
                  onSelect={(item) => {
                    const newMonth = parseInt(item.id, 10);
                    console.log("new Month", newMonth);
                    changeMonth(newMonth);
                  }}
                  type="basic"
                  position="top"
                />
              </div>
              <div className="year-selectbox-container">
                <SelectBox
                  items={yearItems}
                  defaultSelectedItem={defaultSelectedYearItem}
                  onSelect={(item) => {
                    const newYear = parseInt(item.id, 10);
                    changeYear(newYear);
                  }}
                  type="basic"
                  position="top"
                />
              </div>
            </div>

            {!nextMonthButtonDisabled ? (
              <ChevronRight
                onClick={increaseMonth}
                className={"datepicker-nav-btn"}
              />
            ) : (
              <div className="empty-div" />
            )}
          </div>
        )}
        customInput={
          <Input
            isRequired={isRequired}
            className="custom-date-input"
            infoText={infoText}
            val={prepareDateString(startDate)}
            value={prepareDateString(startDate)}
            error={error}
            suffixItem={<CalendarEmpty onClick={() => setIsOpen(!isOpen)} />}
          />
        }
        showPopperArrow={false}
      ></ReactDatePicker>
    </NSDatePickerStyle.Container>
  );
};
