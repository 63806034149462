import { NSDashboardWelcomeTextStyle } from "./DashboardWelcomeText.style";
import { NSDashboardWelcomeTextType } from "./DashboardWelcomeText.types";
import { ReactComponent as ResumeGroup } from "../../assets/icons/resumeGroup.svg";
import { ReactComponent as Apply } from "../../assets/icons/apply.svg";
import { ReactComponent as UserInfo } from "../../assets/icons/userInfo.svg";
import { Button } from "../Button/Button";

export const DashboardWelcomeText = ({
  isMobile,
  applicationCount,
}: NSDashboardWelcomeTextType.IDashboardWelcomeText) => {
  return (
    <NSDashboardWelcomeTextStyle.Container>
      <NSDashboardWelcomeTextStyle.Item>
        <ResumeGroup />
        <NSDashboardWelcomeTextStyle.ItemContainer>
          <NSDashboardWelcomeTextStyle.Title>
            Özgeçmişim
          </NSDashboardWelcomeTextStyle.Title>
          <NSDashboardWelcomeTextStyle.Label>
            Özgeçmişini oluşturabilir ve güncelleyebilirsin.
          </NSDashboardWelcomeTextStyle.Label>
          <NSDashboardWelcomeTextStyle.ButtonDiv>
            <Button
              variant={isMobile ? "text" : "outline"}
              borderColor="#4AA3E2"
              backgroundColor="#FFFFFF"
              height={56}
              color="#4AA3E2"
              fontSize={isMobile ? 14 : 16}
              onClick={() => window.open("/dashboard/cv", "_self")}
            >
              Özgeçmişimi Oluştur
            </Button>
          </NSDashboardWelcomeTextStyle.ButtonDiv>
        </NSDashboardWelcomeTextStyle.ItemContainer>
      </NSDashboardWelcomeTextStyle.Item>
      <NSDashboardWelcomeTextStyle.Item>
        <Apply />
        <NSDashboardWelcomeTextStyle.ItemContainer>
          <NSDashboardWelcomeTextStyle.Title>
            Başvurularım
          </NSDashboardWelcomeTextStyle.Title>
          <NSDashboardWelcomeTextStyle.Label>
            Başvuru yaptığın tüm ilanları görebilirsin.
          </NSDashboardWelcomeTextStyle.Label>
          <NSDashboardWelcomeTextStyle.ButtonDiv>
            {typeof applicationCount !== "undefined" &&
            applicationCount === 0 ? (
              <Button
                variant={isMobile ? "text" : "outline"}
                borderColor="#4AA3E2"
                backgroundColor="#FFFFFF"
                height={56}
                color="#4AA3E2"
                fontSize={isMobile ? 14 : 16}
                onClick={() => window.open("/position/list", "_self")}
              >
                Hemen Başvur
              </Button>
            ) : (
              <Button
                variant={isMobile ? "text" : "outline"}
                borderColor="#4AA3E2"
                backgroundColor="#FFFFFF"
                height={56}
                color="#4AA3E2"
                fontSize={isMobile ? 14 : 16}
                onClick={() => window.open("/dashboard/applications", "_self")}
              >
                Başvuruları İncele
              </Button>
            )}
          </NSDashboardWelcomeTextStyle.ButtonDiv>
        </NSDashboardWelcomeTextStyle.ItemContainer>
      </NSDashboardWelcomeTextStyle.Item>
      <NSDashboardWelcomeTextStyle.Item last>
        <UserInfo />
        <NSDashboardWelcomeTextStyle.ItemContainer>
          <NSDashboardWelcomeTextStyle.Title>
            Üyelik Bilgilerim
          </NSDashboardWelcomeTextStyle.Title>
          <NSDashboardWelcomeTextStyle.Label>
            Şifreni değiştirebilir, üyelik iptalini gerçekleştirebilir ve KVKK
            iznlerini inceleyebilirsin.
          </NSDashboardWelcomeTextStyle.Label>
          <NSDashboardWelcomeTextStyle.ButtonDiv>
            <Button
              variant={isMobile ? "text" : "outline"}
              height={56}
              fontSize={isMobile ? 14 : 16}
              borderColor="#4AA3E2"
              backgroundColor="#FFFFFF"
              color="#4AA3E2"
              onClick={() => window.open("/dashboard/account", "_self")}
            >
              Bilgilerimi Düzenle
            </Button>
          </NSDashboardWelcomeTextStyle.ButtonDiv>
        </NSDashboardWelcomeTextStyle.ItemContainer>
      </NSDashboardWelcomeTextStyle.Item>
    </NSDashboardWelcomeTextStyle.Container>
  );
};
