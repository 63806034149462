import { NSCVOtherStyle } from "./CVPersonalInfoUpload.style";
import { NSCVOtherType } from "./CVPersonalInfoUpload.types";
import { useState } from "react";
import { DragDropFileUpload } from "../DragDropFileUpload/DragDropFileUpload";
import { Button } from "../Button/Button";
import colors from "../../style/colors";

export const CVPersonalInfoUpload = ({
  onSubmitOther,
}: NSCVOtherType.ICVOther) => {
  const [uploadedFile, setUploadedFile] = useState<Blob | undefined>(undefined);

  return (
    <NSCVOtherStyle.Container>
      <NSCVOtherStyle.Title>Öz Geçmişini Yükle</NSCVOtherStyle.Title>
      <NSCVOtherStyle.Description>
        {/* <Button
          onClick={() => window.open("kariyer.net")}
          fontSize={16}
          color={colors.blue.blue01}
          fontWeight={400}
          variant="text"
        >
          Özgeçmiş yükleyin
        </Button>{" "} */}
        <NSCVOtherStyle.Link>Kariyer.net</NSCVOtherStyle.Link> özgeçmişinizi
        yüklemeniz halinde, özgeçmiş alanındaki bazı bilgiler otomatik olarak
        tamamlanacaktır. Özgeçmişinizi manuel olarak da doldurabilirsiniz.
      </NSCVOtherStyle.Description>
      <NSCVOtherStyle.DragAndDropContainer>
        <DragDropFileUpload
          setUploadedFile={setUploadedFile}
          uploadedFile={uploadedFile}
          uploadedFileName={(uploadedFile as File)?.name ?? undefined}
          onUpload={(_, acceptedFile) => {
            setUploadedFile(acceptedFile);
            onSubmitOther({
              cvName: (acceptedFile as File).name,
            });
          }}
        />
      </NSCVOtherStyle.DragAndDropContainer>
    </NSCVOtherStyle.Container>
  );
};
