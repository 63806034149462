import { useNavigate } from "react-router-dom";
import { AuthCard } from "../../../../components/AuthCard/AuthCard";
import { Logo } from "../../../../components/Logo/Logo";
import { NSRegisterPageType } from "../RegisterPage.types";
import { NSRegisterPageMobileStyle } from "./RegisterPageMobile.style";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { SwitchButton } from "../../../../components/SwitchButton/SwitchButton";
import { Input } from "../../../../components/Input/Input";
import { Button } from "../../../../components/Button/Button";
import colors from "../../../../style/colors";
import { PasswordConfirmation } from "../../../../components/PasswordConfirmation/PasswordConfirmation";
import { Checkbox } from "../../../../components/Checkbox/Checkbox";
import { LegalTextModal } from "../../../../components/LegalTextModal/LegalTextModal";
type RegisterSchemaType = z.infer<typeof NSRegisterPageType.registerSchema>;

function RegisterPageMobile({
  registerSchema,
  onSubmitRegister,
}: Readonly<NSRegisterPageType.IRegisterPageData>) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm<RegisterSchemaType>({
    resolver: zodResolver(registerSchema),
  });

  const onSubmit: SubmitHandler<RegisterSchemaType> = (data) => {
    console.log("data", data);
    onSubmitRegister(data);
  };

  const password = useWatch({
    control,
    name: "password",
  });

  const confirmPassword = useWatch({
    control,
    name: "confirmPassword",
  });

  const kvkk = useWatch({
    control,
    name: "kvkk",
  });
  const termsOfUse = useWatch({
    control,
    name: "termsOfUse",
  });
  const referenceCommitment = useWatch({
    control,
    name: "referenceCommitment",
  });

  const firstButton = {
    children: "Giriş Yap",
    onClick: () => {
      navigate("/auth/login");
    },
  };
  const secondButton = {
    children: "Üye Ol",
    onClick: () => {
      navigate("/auth/register");
    },
  };

  const [showPasswordButton, setShowPasswordButton] = useState(false);
  const [showConfirmPasswordButton, setShowConfirmPasswordButton] =
    useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  const onRequestCloseHandler = () => {
    setIsOpen(false);
  };

  return (
    <NSRegisterPageMobileStyle.Container>
      <Logo isDark={true} isMobile={true} />
      <AuthCard isMobile={true}>
        <SwitchButton
          defaultSelectedButton="second"
          firstButton={firstButton}
          secondButton={secondButton}
        ></SwitchButton>
        <NSRegisterPageMobileStyle.Form
          id="registerForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <NSRegisterPageMobileStyle.Title>
            Farklı fonksiyonlarımızdaki kariyer olanaklarına başvurmak için
            giriş yap.
          </NSRegisterPageMobileStyle.Title>
          <NSRegisterPageMobileStyle.InnerForm>
            <Input
              label="Ad"
              placeholder="Ad"
              val={watch("name")}
              {...register("name")}
              error={errors.name?.message}
            />
            <Input
              label="Soyad"
              placeholder="Soyad"
              val={watch("surname")}
              {...register("surname")}
              error={errors.surname?.message}
            />

            <Input
              label="E-posta"
              placeholder="E-posta"
              id="email"
              val={watch("email")}
              {...register("email")}
              error={errors.email?.message}
            />

            <Input
              label="Şifre"
              type={showPasswordButton ? "text" : "password"}
              suffixItem={
                password && (
                  <Button
                    onClick={() => setShowPasswordButton(!showPasswordButton)}
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                  >
                    {showPasswordButton ? "Gizle" : "Göster"}
                  </Button>
                )
              }
              placeholder="Şifre"
              val={watch("password")}
              {...register("password")}
              error={errors.password?.message}
            />
            <Input
              label="Şifre Tekrarı"
              type={showConfirmPasswordButton ? "text" : "password"}
              suffixItem={
                confirmPassword && (
                  <Button
                    onClick={() =>
                      setShowConfirmPasswordButton(!showConfirmPasswordButton)
                    }
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                  >
                    {showConfirmPasswordButton ? "Gizle" : "Göster"}
                  </Button>
                )
              }
              placeholder="Şifre Tekrarı"
              val={watch("confirmPassword")}
              {...register("confirmPassword")}
              error={errors.confirmPassword?.message}
            />
          </NSRegisterPageMobileStyle.InnerForm>
          <PasswordConfirmation password={password} />
          <NSRegisterPageMobileStyle.CheckboxContainer>
            <Checkbox
              error={!!errors.kvkk?.message}
              checked={kvkk ?? false}
              onChange={(value) => setValue("kvkk", value)}
              label={
                <NSRegisterPageMobileStyle.CheckboxLabel>
                  <Button
                    onClick={() => setIsOpen(true)}
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                    textDecoration={true}
                  >
                    Kişisel Verilerin Korunması Kanunu
                  </Button>
                  metnini okudum, onaylıyorum.
                </NSRegisterPageMobileStyle.CheckboxLabel>
              }
              id="kvkk"
            />
            <Checkbox
              error={!!errors.termsOfUse?.message}
              checked={termsOfUse ?? false}
              onChange={(value) => setValue("termsOfUse", value)}
              label={
                <NSRegisterPageMobileStyle.CheckboxLabel>
                  <Button
                    onClick={() => setIsOpen(true)}
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                    textDecoration={true}
                  >
                    Kullanım Koşulları {` `}
                  </Button>
                  metnini okudum, onaylıyorum.
                </NSRegisterPageMobileStyle.CheckboxLabel>
              }
              id="termsOfUse"
            />
            <Checkbox
              error={!!errors.referenceCommitment?.message}
              checked={referenceCommitment ?? false}
              onChange={(value) => setValue("referenceCommitment", value)}
              label={
                <NSRegisterPageMobileStyle.CheckboxLabel>
                  <Button
                    onClick={() => setIsOpen(true)}
                    variant="text"
                    color={colors.blue.blue01}
                    fontSize={12}
                    fontWeight={400}
                    textDecoration={true}
                  >
                    Referans taahhüdünü
                  </Button>
                  okudum, onaylıyorum
                </NSRegisterPageMobileStyle.CheckboxLabel>
              }
              id="referenceCommitment"
            />
          </NSRegisterPageMobileStyle.CheckboxContainer>
          <Button fontSize={16} height={43} type="submit" variant="solid">
            Üye Ol
          </Button>
        </NSRegisterPageMobileStyle.Form>
      </AuthCard>
      <NSRegisterPageMobileStyle.FooterText>
        Doğuş Kariyer’e üyeysen eğer
        <Button
          onClick={() => navigate("/auth/login")}
          variant="text"
          color={colors.information.information01}
          fontSize={14}
        >
          Giriş Yap
        </Button>
      </NSRegisterPageMobileStyle.FooterText>

      <LegalTextModal
        isMobile={true}
        legalText="Sayın Kullanıcı, Doğuş Otomotiv’e ait http:// www.dogusotomotiv.com.tr web sitesine hoş geldiniz.
“Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması Politikası ve Aydınlatma Metni” siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin sağlanmasına ilişkin hükümleri düzenlemektedir.
Aşağıdaki tanımlar Doğuş Otomotiv’e ait http://www.dogusotomotiv.com.tr  sitesinin kullanımı için önemlidir.
Buna göre;
Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri,Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri, Açık PozisyonlarAçık PozisyonlarSayın Kullanıcı, Doğuş Otomotiv’e ait http:// www.dogusotomotiv.com.tr web sitesine hoş geldiniz.
“Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması Politikası ve Aydınlatma Metni” siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin sağlanmasına ilişkin hükümleri düzenlemektedir.
Aşağıdaki tanımlar Doğuş Otomotiv’e ait http://www.dogusotomotiv.com.tr  sitesinin kullanımı için önemlidir.
Buna göre;
Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri,Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri, Açık PozisyonlarAçık PozisyonlarSayın Kullanıcı, Doğuş Otomotiv’e ait http:// www.dogusotomotiv.com.tr web sitesine hoş geldiniz.
“Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması Politikası ve Aydınlatma Metni” siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin sağlanmasına ilişkin hükümleri düzenlemektedir.
Aşağıdaki tanımlar Doğuş Otomotiv’e ait http://www.dogusotomotiv.com.tr  sitesinin kullanımı için önemlidir.
Buna göre;
Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri,Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri, Açık PozisyonlarAçık PozisyonlarSayın Kullanıcı, Doğuş Otomotiv’e ait http:// www.dogusotomotiv.com.tr web sitesine hoş geldiniz.
“Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması Politikası ve Aydınlatma Metni” siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin sağlanmasına ilişkin hükümleri düzenlemektedir.
Aşağıdaki tanımlar Doğuş Otomotiv’e ait http://www.dogusotomotiv.com.tr  sitesinin kullanımı için önemlidir.
Buna göre;
Sayın Kullanıcı, Doğuş Otomotiv’e ait http:// www.dogusotomotiv.com.tr web sitesine hoş geldiniz.
“Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması Politikası ve Aydınlatma Metni” siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin sağlanmasına ilişkin hükümleri düzenlemektedir.
Aşağıdaki tanımlar Doğuş Otomotiv’e ait http://www.dogusotomotiv.com.tr  sitesinin kullanımı için önemlidir.
Buna göre;
Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri,Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri, Açık PozisyonlarAçık PozisyonlarSayın Kullanıcı, Doğuş Otomotiv’e ait http:// www.dogusotomotiv.com.tr web sitesine hoş geldiniz.
“Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması Politikası ve Aydınlatma Metni” siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin sağlanmasına ilişkin hükümleri düzenlemektedir.
Aşağıdaki tanımlar Doğuş Otomotiv’e ait http://www.dogusotomotiv.com.tr  sitesinin kullanımı için önemlidir.
Buna göre;
Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri,Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri, Açık PozisyonlarAçık PozisyonlarSayın Kullanıcı, Doğuş Otomotiv’e ait http:// www.dogusotomotiv.com.tr web sitesine hoş geldiniz.
“Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması Politikası ve Aydınlatma Metni” siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin sağlanmasına ilişkin hükümleri düzenlemektedir.
Aşağıdaki tanımlar Doğuş Otomotiv’e ait http://www.dogusotomotiv.com.tr  sitesinin kullanımı için önemlidir.
Buna göre;
Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri,Kullanıcı/Kullanıcılar: Kişisel verilerini, sitede yer alan iletişim formlarının talep ettiği bilgileri kendi açık rızaları ile vererek siteye üye olan veya kişisel verilerini vermeden sadece siteyi ziyaret eden gerçek kişileri, Açık PozisyonlarAçık PozisyonlarSayın Kullanıcı, Doğuş Otomotiv’e ait http:// www.dogusotomotiv.com.tr web sitesine hoş geldiniz.
“Kullanım Şartları Ve Gizlilik/Kişisel Verilerin Korunması Politikası ve Aydınlatma Metni” siz değerli kullanıcılarımıza web sitemizde yer alan bilgi ve hizmetlerin sağlanmasına ilişkin hükümleri düzenlemektedir.
Aşağıdaki tanımlar Doğuş Otomotiv’e ait http://www.dogusotomotiv.com.tr  sitesinin kullanımı için önemlidir.
Buna göre;"
        isOpen={modalIsOpen}
        onCloseHandler={onRequestCloseHandler}
      />
    </NSRegisterPageMobileStyle.Container>
  );
}

export default RegisterPageMobile;
