import { NSDashboardMenuType } from "../components/DashboardMenu/DashboardMenu.types";
import { ReactComponent as CalendarEmpty } from "../assets/icons/calendarEmpty.svg";
import { ReactComponent as Layers } from "../assets/icons/layers.svg";
import { ReactComponent as News } from "../assets/icons/news.svg";
import { ReactComponent as DoubleCheck } from "../assets/icons/doubleCheck.svg";
import { ReactComponent as UserEmpty } from "../assets/icons/userEmpty.svg";
import colors from "../style/colors";

export const dashbordMenuItems: NSDashboardMenuType.IDashboardMenuItem[] = [
  {
    label: "Güncel Durumum",
    icon: <CalendarEmpty stroke={colors.neutrals.grey04} />,
    link: "/dashboard/status",
  },
  {
    label: "Açık Pozisyonlar",
    icon: <Layers stroke={colors.neutrals.grey04} />,
    link: "/position/list",
  },
  {
    label: "Özgeçmişim",
    icon: <News stroke={colors.neutrals.grey04} />,
    link: "/dashboard/cv",
  },
  {
    label: "Başvurularım",
    icon: <DoubleCheck fill={colors.neutrals.grey04} />,
    link: "/dashboard/applications",
  },
  {
    label: "Üyelik Bilgilerim",
    icon: (
      <UserEmpty
        color={colors.neutrals.grey04}
        stroke={colors.neutrals.grey04}
      />
    ),
    link: "/dashboard/account",
  },
];
