import { NSDashboardEmptyApplicationsStyle } from "./DashboardEmptyApplications.style";
import { ReactComponent as Apply } from "../../assets/icons/apply.svg";
import { Button } from "../Button/Button";
import { NSDashboardEmptyApplicationsType } from "./DashboardEmptyApplications.types";

export const DashboardEmptyApplications = ({
  isMobile,
}: NSDashboardEmptyApplicationsType.IDashboardEmptyApplications) => {
  return (
    <NSDashboardEmptyApplicationsStyle.Container>
      <NSDashboardEmptyApplicationsStyle.Item>
        <Apply />
        <NSDashboardEmptyApplicationsStyle.ItemContainer>
          <NSDashboardEmptyApplicationsStyle.Title>
            Bir başvuru yapmadınız.
          </NSDashboardEmptyApplicationsStyle.Title>
          <NSDashboardEmptyApplicationsStyle.Label>
            Henüz hiçbir başvuru yapmadın. Başvuru yapmak için Açık Pozisyonlar
            sekmesini ziyaret edebilirsin.
          </NSDashboardEmptyApplicationsStyle.Label>
          <NSDashboardEmptyApplicationsStyle.ButtonDiv>
            <Button
              variant={isMobile ? "text" : "outline"}
              borderColor="#4AA3E2"
              backgroundColor="#FFFFFF"
              height={56}
              color="#4AA3E2"
              fontSize={isMobile ? 14 : 16}
              onClick={() => window.open("/position/list", "_self")}
            >
              Açık pozisyonlar
            </Button>
          </NSDashboardEmptyApplicationsStyle.ButtonDiv>
        </NSDashboardEmptyApplicationsStyle.ItemContainer>
      </NSDashboardEmptyApplicationsStyle.Item>
    </NSDashboardEmptyApplicationsStyle.Container>
  );
};
