import styled from "styled-components";
import colors from "../../../../style/colors";

export namespace NSCVPageMobileStyle {
  export const Container = styled.div``;

  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 5;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;
  export const StickyHelperContainer = styled.div`
    position: relative;
  `;

  export const MainContainer = styled.div`
    padding: 24px 16px 0px 16px;
  `;

  export const Content = styled.div``;

  export const PageTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    text-align: left;
    margin-bottom: 4px;
  `;

  export const PageLabel = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 22.96px;
    text-align: left;
    margin-bottom: 4px;
  `;

  export const CvUploadContainer = styled.div`
    padding: 0 12px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: fit-content;
    height: 52px;
    margin-bottom: 24px;
    border-radius: 8px;
    background-color: ${colors.shadesOflight.light32};
    font-weight: 400px;
    font-size: 14px;
    line-height: 28.08px;
    gap: 6px;
  `;

  export const OtherContainer = styled.span`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    gap: 16px;
    align-items: center;
  `;

  export const OtherTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 37.44px;
    text-align: left;
    margin-bottom: 24px;
    display: flex;
  `;

  export const FormContainer = styled.div`
    background-color: ${colors.light.light};
    padding: 32px;
    margin-top: 8px;
    border-radius: 16px;

    @media (max-width: 768px) {
      border-radius: 0px;
      margin-top: 0px;
    }
  `;

  export const FormContainerWithoutBg = styled.div`
    margin-top: 8px;

    @media (max-width: 768px) {
      border-radius: 0px;
      margin-top: 0px;
    }
  `;

  export const FormTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    text-align: left;
    margin-bottom: 24px;
    display: flex;
  `;

  export const Star = styled.span`
    font-size: 18px;
    font-weight: 500;
    line-height: 28.08px;
    text-align: left;
    color: ${colors.dark.dark};
  `;
}
