import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Input } from "../Input/Input";
import { NSCvPersonalInfoStyle } from "./CvPersonalInfo.style";
import { NSCvPersonalInfoType } from "./CvPersonalInfo.types";
import { zodResolver } from "@hookform/resolvers/zod";
import { SelectBox } from "../SelectBox/SelectBox";
import { RadioButton } from "../RadioButton/RadioButton";
import { Button } from "../Button/Button";
import { DatePicker } from "../DatePicker/DatePicker";
import {
  driverClasses,
  genderArray,
  nationalities,
} from "../../util/formDropdownItems";
import { useEffect } from "react";
import { scrollToTop } from "../../util/scrollToTop";
import { CVPersonalInfoUpload } from "../CVPersonalInfoUpload/CVPersonalInfoUpload";

export const CvPersonalInfo = ({
  onSubmitPersonalInfo,
  onSubmitOther,
}: NSCvPersonalInfoType.ICvPersonalInfo) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<NSCvPersonalInfoType.CVPersonalInfoType>({
    resolver: zodResolver(NSCvPersonalInfoType.CvPersonalInfoSchema),
  });

  const onSubmit: SubmitHandler<NSCvPersonalInfoType.CVPersonalInfoType> = (
    data
  ) => {
    onSubmitPersonalInfo(data);
  };

  useEffect(() => {
    console.log("error", errors);
  }, [errors]);
  const driverLicence = useWatch({
    control,
    name: "driverLicence",
  });

  const militaryStatus = useWatch({
    control,
    name: "militaryStatus",
  });

  const gender = useWatch({
    control,
    name: "gender",
  });

  return (
    <NSCvPersonalInfoStyle.Wrapper>
      <NSCvPersonalInfoStyle.Container>
        <CVPersonalInfoUpload onSubmitOther={onSubmitOther} />
      </NSCvPersonalInfoStyle.Container>
      <NSCvPersonalInfoStyle.Container>
        <NSCvPersonalInfoStyle.Title>
          Kişisel Bilgiler *
        </NSCvPersonalInfoStyle.Title>
        <NSCvPersonalInfoStyle.FormContainer
          onSubmit={handleSubmit(onSubmit)}
          id="CvPersonalInfo"
        >
          <NSCvPersonalInfoStyle.SideBySideContainer>
            <Input
              label="Ad"
              val={watch("name")}
              placeholder="Ad"
              isRequired
              {...register("name")}
              error={errors.name?.message}
            />
            <Input
              label="Soyad"
              val={watch("surname")}
              placeholder="Soyad"
              isRequired
              {...register("surname")}
              error={errors.surname?.message}
            />
          </NSCvPersonalInfoStyle.SideBySideContainer>

          <NSCvPersonalInfoStyle.SideBySideContainer>
            <DatePicker
              placeholder="Doğum Tarihi"
              isRequired
              infoText="GG/AA/YYYY biçiminde."
              onChange={(date) => setValue("birthDate", date?.toDateString())}
              error={errors.birthDate?.message}
            />

            <SelectBox
              label="Cinsiyetin"
              placeholder="Cinsiyetin"
              isRequired
              items={genderArray}
              onSelect={(item) => setValue("gender", item)}
              error={errors.gender?.message}
            />
          </NSCvPersonalInfoStyle.SideBySideContainer>

          <NSCvPersonalInfoStyle.SideBySideContainer>
            <SelectBox
              label="Uyruğun"
              placeholder="Uyruğun"
              isRequired
              items={nationalities}
              onSelect={(item) => setValue("nationality", item)}
              error={errors.nationality?.message}
            />
          </NSCvPersonalInfoStyle.SideBySideContainer>

          <NSCvPersonalInfoStyle.BottomToTopContainer>
            <NSCvPersonalInfoStyle.AskTitle>
              Ehliyetin var mı ?
              <NSCvPersonalInfoStyle.Star>*</NSCvPersonalInfoStyle.Star>
            </NSCvPersonalInfoStyle.AskTitle>
            <RadioButton
              options={[
                { label: "Evet", value: "yes", id: "yes" },
                { label: "Hayır", value: "no", id: "no" },
              ]}
              onChange={(item) => setValue("driverLicence", item.value)}
            />
            {driverLicence === "yes" && (
              <SelectBox
                label="Ehliyet Sınıfın"
                placeholder="Ehliyet Sınıfın"
                isRequired
                items={driverClasses}
                onSelect={(item) => setValue("driverClass", item)}
                error={errors.driverClass?.message}
              />
            )}
          </NSCvPersonalInfoStyle.BottomToTopContainer>
          {driverLicence === "yes" && (
            <NSCvPersonalInfoStyle.BottomToTopContainer>
              <NSCvPersonalInfoStyle.AskTitle>
                Aktif olarak araç kullanabilir misin?{" "}
                <NSCvPersonalInfoStyle.Star>*</NSCvPersonalInfoStyle.Star>
              </NSCvPersonalInfoStyle.AskTitle>

              <RadioButton
                options={[
                  { label: "Evet", value: "yes", id: "yes" },
                  { label: "Hayır", value: "no", id: "no" },
                ]}
                onChange={(item) => setValue("activeDrivingInfo", item.value)}
              />
            </NSCvPersonalInfoStyle.BottomToTopContainer>
          )}
          {gender && gender.id === "male" && (
            <NSCvPersonalInfoStyle.BottomToTopContainer>
              <NSCvPersonalInfoStyle.AskTitle>
                Askerlik Durumun
                <NSCvPersonalInfoStyle.Star>*</NSCvPersonalInfoStyle.Star>
              </NSCvPersonalInfoStyle.AskTitle>
              <RadioButton
                options={[
                  { label: "Evet", value: "yes", id: "yes" },
                  { label: "Hayır", value: "no", id: "no" },
                  { label: "Muaf", value: "exempt", id: "exempt" },
                ]}
                onChange={(item) => setValue("militaryStatus", item.value)}
              />

              {militaryStatus === "yes" && (
                <DatePicker
                  placeholder="Terhis Tarihi"
                  infoText="GG/AA/YYYY biçiminde."
                  onChange={(date) =>
                    setValue("dischargeDate", date?.toDateString())
                  }
                  error={errors.dischargeDate?.message}
                />
              )}

              {militaryStatus === "no" && (
                <DatePicker
                  placeholder="Tecil Tarihi"
                  infoText="GG/AA/YYYY biçiminde."
                  onChange={(date) =>
                    setValue("postponementDate", date?.toDateString())
                  }
                  error={errors.postponementDate?.message}
                />
              )}
              {militaryStatus === "exempt" && (
                <Input
                  label="Muafiyet Sebebi"
                  val={watch("exemptionReason")}
                  placeholder="Muafiyet Sebebi"
                  {...register("exemptionReason")}
                  error={errors.exemptionReason?.message}
                />
              )}
            </NSCvPersonalInfoStyle.BottomToTopContainer>
          )}

          <Button
            width={105}
            height={56}
            type="submit"
            variant="solid"
            onClick={scrollToTop}
          >
            Kaydet
          </Button>
        </NSCvPersonalInfoStyle.FormContainer>
      </NSCvPersonalInfoStyle.Container>
    </NSCvPersonalInfoStyle.Wrapper>
  );
};
