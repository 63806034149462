import { Modal } from "../Modal/Modal";
import { NSLegalTextModalStyle } from "./LegalTextModal.style";
import { NSLegalTextModalType } from "./LegalTextModal.types";

export const LegalTextModal = ({
  isMobile = false,
  isOpen,
  onCloseHandler,
  legalText,
}: NSLegalTextModalType.ILegalTextModal) => {
  return (
    <Modal isOpen={isOpen} isMobile={isMobile} onCloseHandler={onCloseHandler}>
      <NSLegalTextModalStyle.ModalContentDiv isMobile={isMobile}>
        <NSLegalTextModalStyle.ModalTitle>
          Kullanım Koşulları
        </NSLegalTextModalStyle.ModalTitle>
        <NSLegalTextModalStyle.ModalContentDesc>
          {legalText}
        </NSLegalTextModalStyle.ModalContentDesc>
      </NSLegalTextModalStyle.ModalContentDiv>
    </Modal>
  );
};
