import { HeaderDesktop } from "../../../../components/HeaderDesktop/HeaderDesktop";
import { NSCVPageType } from "../CVPage.types";
import { Footer } from "../../../../components/Footer/Footer";
import { scrollToTop } from "../../../../util/scrollToTop";
import { CircleButton } from "../../../../components/CircleButton/CircleButton";
import { NSCVPageDesktopStyle } from "./CVPageDesktop.style";
import colors from "../../../../style/colors";
import { DashboardMenu } from "../../../../components/DashboardMenu/DashboardMenu";
import { dashbordMenuItems } from "../../../../util/dashboardMenuItem";
import { CvFormStep } from "../../../../components/CvFormStep/CvFormStep";
import { useState } from "react";
import { formSteps } from "../../../../util/formStep";
import { CvPersonalInfo } from "../../../../components/CvPersonalInfo/CvPersonalInfo";
import { CvContact } from "../../../../components/CvContact/CvContact";
import { CvEducation } from "../../../../components/CvEducation/CvEducation";
import { CvSupplementary } from "../../../../components/CvSupplementary/CvSupplementary";
import { CVOther } from "../../../../components/CVOther/CVOther";
import { NSCvFormStepType } from "../../../../components/CvFormStep/CvFormStep.types";
import { CvUploadModal } from "../../../../components/CvUploadModal/CvUploadModal";
import { CVExperiences } from "../../../../components/CVExperiences/CVExperiences";

function CVPageDesktop({ cvData }: Readonly<NSCVPageType.ICVPage>) {
  const isMobile = false;
  const [activeStep, setActiveStep] =
    useState<NSCvFormStepType.ICvFormStepItem>(
      formSteps.find(
        (item) => item.id === "kisisel-bilgiler"
      ) as NSCvFormStepType.ICvFormStepItem
    );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const stepToForm: { [key: string]: JSX.Element } = {
    "kisisel-bilgiler": (
      <CvPersonalInfo
        onSubmitOther={(data) => {
          console.log("data", data);
        }}
        onSubmitPersonalInfo={(data) => {
          console.log("data", data);
          setActiveStep(
            formSteps.find(
              (item) => item.id === "iletisim-bilgileri"
            ) as NSCvFormStepType.ICvFormStepItem
          );
        }}
      />
    ),
    "iletisim-bilgileri": (
      <CvContact
        onSubmitContact={(data) => {
          console.log("data", data);
          setActiveStep(
            formSteps.find(
              (item) => item.id === "ogrenim-durumu"
            ) as NSCvFormStepType.ICvFormStepItem
          );
        }}
      />
    ),
    "ogrenim-durumu": (
      <CvEducation
        onSubmitEducation={(data) => {
          console.log("data", data);
          setActiveStep(
            formSteps.find(
              (item) => item.id === "tamamlayıcı-bilgiler"
            ) as NSCvFormStepType.ICvFormStepItem
          );
        }}
      />
    ),
    "tamamlayıcı-bilgiler": (
      <CvSupplementary
        onSubmitSupplementary={(data) => {
          console.log("data", data);
          setActiveStep(
            formSteps.find(
              (item) => item.id === "diger-alanlar"
            ) as NSCvFormStepType.ICvFormStepItem
          );
        }}
      />
    ),
    "diger-alanlar": (
      <NSCVPageDesktopStyle.OtherContainer>
        <CVExperiences
          onSubmitExperiences={(data) => {
            console.log("data", data);
          }}
        />
      </NSCVPageDesktopStyle.OtherContainer>
    ),
  };

  return (
    <NSCVPageDesktopStyle.Container>
      <HeaderDesktop
        logoClicked={() => {}}
        anchorList={cvData.anchorList}
        isDark={true}
        backgroundColor={colors.neutrals.grey01}
      />
      <NSCVPageDesktopStyle.MainContainer>
        <DashboardMenu menuItem={dashbordMenuItems} />
        <NSCVPageDesktopStyle.Content>
          <NSCVPageDesktopStyle.PageTitle>
            Özgeçmişim
          </NSCVPageDesktopStyle.PageTitle>
          <NSCVPageDesktopStyle.PageLabel>
            Aşağıdaki formu yıldızlı (*) alanlara dikkat ederek doldur ve
            özgeçmişini oluştur.
          </NSCVPageDesktopStyle.PageLabel>

          <CvFormStep
            isMobile={isMobile}
            activeStep={activeStep}
            onChangeStep={(value) => setActiveStep(value)}
          />
          {activeStep.id === "kisisel-bilgiler" && (
            <NSCVPageDesktopStyle.FormContainerWithoutBg>
              {stepToForm[activeStep.id]}
            </NSCVPageDesktopStyle.FormContainerWithoutBg>
          )}

          {activeStep.id === "diger-alanlar" && (
            <>
              <NSCVPageDesktopStyle.OtherTitle>
                Ekleyebileceğin Diğer Alanlar
              </NSCVPageDesktopStyle.OtherTitle>
              {stepToForm[activeStep.id]}
            </>
          )}
          {(activeStep.id === "iletisim-bilgileri" ||
            activeStep.id === "ogrenim-durumu" ||
            activeStep.id === "tamamlayıcı-bilgiler") && (
            <NSCVPageDesktopStyle.FormContainer>
              <NSCVPageDesktopStyle.FormTitle>
                {activeStep.title}
                {activeStep.isRequired && (
                  <NSCVPageDesktopStyle.Star>*</NSCVPageDesktopStyle.Star>
                )}
              </NSCVPageDesktopStyle.FormTitle>

              {stepToForm[activeStep.id]}
            </NSCVPageDesktopStyle.FormContainer>
          )}
        </NSCVPageDesktopStyle.Content>
      </NSCVPageDesktopStyle.MainContainer>

      <Footer {...cvData.footer} isMobile={isMobile} />
      <CircleButton onClick={scrollToTop} isMobile={isMobile} />
      <CvUploadModal
        onCloseHandler={() => setIsModalOpen(false)}
        isMobile={isMobile}
        onAcceptHandler={(file) => console.log("file", file)}
        onDeclineHandler={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </NSCVPageDesktopStyle.Container>
  );
}

export default CVPageDesktop;
